import React from 'react'
import { NotFoundHero } from '../components/404/NotFoundHero/NotFoundHero'
import Layout from '../components/Shared/Layout/Layout'
import { SEO } from '../components/Shared/SEO/SEO'

const NotFoundPage = () => (
	<Layout>
		<SEO
			description="Očná ambulancia v centre Banskej Bystrice ponúka komplexnú zdravotnú starostlivosť, ľudský prístup, príjemné prostredie a minimálny čas strávený čakaním na vyšetrenie vďaka bezplatnému objednávaniu na čas."
			title="Súkromná očná ambulancia"
		/>
		<NotFoundHero />
	</Layout>
)

export default NotFoundPage
