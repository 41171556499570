import * as React from 'react'

function SvgBg(props) {
	return (
		<svg viewBox="0 0 1920 1080" fill="none" {...props}>
			<circle cx={960.5} cy={539.5} r={310.5} fill="#BECBFF" />
			<ellipse cx={960.5} cy={539.5} rx={540.5} ry={540} fill="#BECBFF" fillOpacity={0.69} />
			<ellipse cx={960.5} cy={540} rx={858.5} ry={858} fill="#BECBFF" fillOpacity={0.4} />
			<ellipse cx={960.5} cy={540} rx={1241.5} ry={1241} fill="#BECBFF" fillOpacity={0.2} />
		</svg>
	)
}

export default SvgBg
