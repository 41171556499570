import { Link } from 'gatsby'
import React from 'react'
import { Button } from '../../Shared/Button/Button'
import SvgBg from './assets/Bg.'

export interface NotFoundHeroProps {
	className?: string
}

export const NotFoundHero = (props: NotFoundHeroProps) => {
	const { className = '' } = props
	return (
		<section className="h-screen relative w-full overflow-hidden">
			<div className="bg z-0 absolute top-0 bottom-0 right-0 left-0 m-auto">
				<SvgBg className="h-full w-full" />
			</div>

			<div className=" h-full flex flex-col justify-center items-center relative z-10">
				<h1 className="md:text-9xl   text-4xl">404</h1>
				<h2 className="md:text-6xl text-3xl text-center text-black pt-6">Stránka neexistuje</h2>

				<div className="mt-6">
					<Link to="/">
						<Button>Späť na domovskú stránku</Button>
					</Link>
				</div>
			</div>
		</section>
	)
}
